<template>
	<PageLayout :title="$t('deposit.title')">
		<div class="cashier-box cashier-box--deposit">
			<payment-logo :custom-logo="'m-pesa-logo.svg'" />
			<div class="small-width overflow-visible">
				<payment-wallet />
			</div>

			<div class="line-separator-wrapper">
				<div class="line-separator" />
			</div>

			<div class="small-width">
				<MaterialPhoneInput :mobilenumber="form.phone.mobilenumber" @set-mobile-number="setMobileNumber" />
				<div v-if="!!formErrors['mobilenumber']" class="error">
					{{ !!formErrors ? formErrors['mobilenumber'] : '' }}
				</div>
			</div>

			<amount-buttons
				:buttons-value="currentMethod.predefinedDepositAmounts"
				:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
				:currency="'KES'"
				:form-errors="formErrors['amount']"
				:current-method="currentMethod"
				@set-button-value="setButtonValue"
				@input-amount-value-validation="setFormValidation"
			/>

			<div class="small-width">
				<promocode
					:form-promo-value="form.promocode"
					:form-error="formErrors['promocode']"
					@set-promo-value="setPromoValue"
				/>
			</div>

			<button
				type="button"
				class="button primary-button deposit center"
				:disabled="!form.validated"
				@click="payWithDusupay()"
			>
				{{ $t('deposit.top-up') }}
			</button>

			<div v-if="loading" class="loader-wrapper">
				<loader />
			</div>
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';
import Analytics from '@/utils/analytics';

const loader = () => import('../../../../shared/loader/Loader.vue');
const materialPhoneInput = () => import('../../../../shared/material-phone-input/MaterialPhoneInput');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const promocode = () => import('../../../../shared/promocode/Promocode');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DusupayMpesa',
	components: {
		PageLayout,
		loader,
		materialPhoneInput,
		amountButtons,
		promocode,
		paymentLogo,
		paymentWallet,
	},
	data() {
		return {
			form: {
				amount: null,
				method: 'MOBILE_MONEY',
				paymentOption: {
					id: 'mpesa_ke',
				},
				phone: {
					mobilenumber: '',
					isValid: false,
				},
				promocode: null,
				validated: true,
			},
			methods: ['MOBILE_MONEY'],
			formErrors: {
				mobilenumber: null,
				amount: null,
				promocode: null,
			},
			paymentOptions: null,
			loading: false,
			paymentDone: false,
			walletId: null,
		};
	},
	computed: {
		...mapGetters(['getUser', 'getCurrentMethod']),
		currentMethod() {
			return this.getCurrentMethod('dusupay_mpesa');
		},
		country() {
			return this.getUser.country;
		},
		mobilenumber() {
			return this.getUser.mobilenumber;
		},
	},
	watch: {
		mobilenumber(number) {
			this.form.phone.mobilenumber = number;
		},
	},
	async mounted() {
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setMobileNumber(event) {
			this.form.phone.mobilenumber = event.number.e164;
			this.form.phone.isValid = event.isValid;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		setFormValidation(event) {
			this.form.validated = event;
		},
		async payWithDusupay() {
			if (Boolean(this.form.paymentOption) && this.form.amount) {
				this.loading = true;
				try {
					const currency = 'KES';

					try {
						const response = await apiClient.payWithDusupayMpesa(
							this.walletId,
							currency,
							this.form.amount,
							this.form.method,
							this.form.paymentOption.id,
							this.form.phone.mobilenumber,
							`${window.location.origin}/payments/deposit/confirmation?module=Deposit&method=mpesa`,
							this.form.promocode
						);
						this.loading = false;
						if (response.status === 200) {
							if (this.form.method === 'MOBILE_MONEY') {
								Analytics.trackEvent({
									event: 'Deposit',
									value: this.form.amount,
									label: 'DusupayMpesa',
								});

								this.$router.push({
									path: '/payments/deposit/confirmation',
									query: {
										status: 'success',
										method: 'mpesa',
									},
								});
							}
						} else {
							console.warn(response.status);
						}
					} catch (error) {
						this.loading = false;

						const walletId = this.$route.params.walletId;
						this.$router.push({
							path: `/payments/deposit/${walletId}/confirmation`,
							query: { status: 'error', method: 'mpesa' },
						});
					}
				} catch (e) {
					console.error(e);
					const walletId = this.$route.params.walletId;
					this.$router.push({
						path: `/payments/deposit/${walletId}/confirmation`,
						query: { status: 'error' },
					});
				}
			}
		},
	},
};
</script>
<style scoped>
button.btn-submit {
	margin-top: 35px;
}
</style>
